<template>
  <div class="home">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <!-- <div class="banner swiper-slide">
          <img class="bannerBg" src="https://image.ceekee.com/officialwebsite/banner.png"/>
          <img class="fontImg" src="https://image.ceekee.com/officialwebsite/wenan.png"/>
          <router-link class="linkImg" :to="this.$config.sign_url">
            <img src="https://image.ceekee.com/officialwebsite/sign_btn.png"/>
          </router-link>
          <div class="otherImg">
            <p>
              <img src="https://image.ceekee.com/pc/tripwise/20200117_2.png"/>
              <img src="https://image.ceekee.com/pc/tripwise/20200117_1.png"/>
              <img src="https://image.ceekee.com/pc/tripwise/20200117_3.png"/>
            </p>
          </div>
        </div> -->
        <div class="banner swiper-slide">
          <img class="bannerBg" src="https://image.ceekee.com/pc/tripwise/20210106_1.png"/>
          <img class="fontImg" src="https://image.ceekee.com/pc/tripwise/20210115_11.png"/>
          <router-link class="linkImg" :to="this.$config.appointment_url">
            <img src="https://image.ceekee.com/pc/tripwise/20210106_2.png"/>
          </router-link>
          <div class="otherImg">
            <p>
              <img src="https://image.ceekee.com/pc/tripwise/20200117_2.png"/>
              <img src="https://image.ceekee.com/pc/tripwise/20200117_1.png"/>
              <img src="https://image.ceekee.com/pc/tripwise/20200117_3.png"/>
            </p>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>

    <div class="bannerPhone">
      现在咨询（180-5110-2396）
      <span>免费赠送 企业差旅管理执行方案一份</span>
      <router-link :to="this.$config.appointment_url">立即获取</router-link>
    </div>

    <!-- 第一部分 -->
    <div class="section one">
      <div class="content">
        <div class="title">
          <p>一站式采购+企业支付+费控为核心</p>
          <span>帮助企业提升差旅管理、控制费用支出</span>
        </div>
        <div class="companyPay">
          <img src="https://image.ceekee.com/pc/tripwise/20210116_61.png"/>
        </div>
        <div class="companyPay two">
          <img src="https://image.ceekee.com/pc/tripwise/20210116_71.png"/>
        </div>
      </div>
    </div>

    <!-- 第二部分 -->
    <div class="section two">
      <div class="content">
        <div class="title">
          <p>直击企业差旅痛点</p>
          <span>提升各类角色的满意度</span>
        </div>

        <div class="sectionTwo">
          <div class="sectionTwo_li one">
            <img src="https://image.ceekee.com/pc/tripwise/20200116_1.png"/>
          </div>
          <div class="sectionTwo_li two">
            <img src="https://image.ceekee.com/pc/tripwise/20200116_2.png"/>
          </div>
          <div class="sectionTwo_li three">
            <img src="https://image.ceekee.com/pc/tripwise/20200116_3.png"/>
          </div>
          <div class="sectionTwo_li four">
            <img src="https://image.ceekee.com/pc/tripwise/20200116_4.png"/>
          </div>
        </div>
      </div>
    </div>

    <!-- 第三部分 -->
    <div class="section three">
      <div class="title">
        <p>全新的企业差旅管理流程</p>
        <span>告别传统/低效的企业差旅</span>
      </div>
      <div class="sectionThree">
        <div class="sectionThree_li">
          <img src="https://image.ceekee.com/pc/tripwise/20210115_21.png"/>
        </div>
        <div class="sectionThree_li">
          <img src="https://image.ceekee.com/pc/tripwise/20210115_22.png"/>
        </div>
        <div class="sectionThree_li">
          <img src="https://image.ceekee.com/pc/tripwise/20210115_23.png"/>
        </div>
        <div class="sectionThree_li">
          <img src="https://image.ceekee.com/pc/tripwise/20210115_24.png"/>
        </div>
        <div class="sectionThree_li">
          <img src="https://image.ceekee.com/pc/tripwise/20210115_25.png"/>
        </div>
      </div>
      <router-link :to="this.$config.appointment_url">
        <img class="appointment" src="https://image.ceekee.com/pc/tripwise/20210106_24.png"/>
      </router-link>
    </div>

    <!-- 第四部分 -->
    <div class="section four">
      <div class="content">
        <div class="title">
          <p>6大管家式服务</p>
          <span>站在企业角度解决所有差旅问题</span>
        </div>
        <div class="sectionFour">
          <div class="sectionFour_li">
            <img src="https://image.ceekee.com/pc/tripwise/20210106_26.png"/>
            <p>一站式采购</p>
            <span>
              差旅出行消费一站解决
              <br/>杜绝跨平台消费的困扰
            </span>
          </div>
          <div class="sectionFour_li">
            <img src="https://image.ceekee.com/pc/tripwise/20210106_27.png"/>
            <p>严选服务</p>
            <span>
              海量供应商严选资源
              <br/>总能找到您想要的低价
            </span>
          </div>
          <div class="sectionFour_li">
            <img src="https://image.ceekee.com/pc/tripwise/20210106_28.png"/>
            <p>统一结算</p>
            <span>
              月结垫资服务
              <br/>避免反复请款，不占用
              <br/>企业资金流
            </span>
          </div>
          <div class="sectionFour_li">
            <img src="https://image.ceekee.com/pc/tripwise/20210106_29.png"/>
            <p>数据分析</p>
            <span>
              部门/项目/节省/浪费等27个
              <br/>纬度的数据分析，专业差旅建议
            </span>
          </div>
          <div class="sectionFour_li">
            <img src="https://image.ceekee.com/pc/tripwise/20210106_30.png"/>
            <p>专属VIP</p>
            <span>
              7*24小时在线服务
              <br/>2对1微信群处理，高速响应
            </span>
          </div>
          <div class="sectionFour_li">
            <img src="https://image.ceekee.com/pc/tripwise/20210106_31.png"/>
            <p>协议托管</p>
            <span>
              支持及酒店集团、
              <br/>各大航司的协议托管
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- 第五部分 -->
    <Partner></Partner>
  </div>
</template>

<script>
import Partner from "@/components/Partner";
import Swiper from 'swiper';
import "swiper/css/swiper.css";

export default {
  components: {
    Partner
  },
  methods: {
    initSwiper() {
      setTimeout(() => {
        let mySwiper = new Swiper(".swiper-container", {
          slidesPerView: 1,
          // 改变拖动时鼠标指针形状
          grabCursor: true,
          // 设置轮播循环方式
          loop: true,
          // 自动播放间隔时间
          autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          },
          // 分页器
          pagination: {
            el: ".swiper-pagination",
            bulletClass: 'my-bullet',
            bulletActiveClass: 'my-bullet-active',
            clickable: true
          },
          // 轮播效果
          effect: "slide"
        })
      }, 100)
    }
  },
  mounted() {
    // this.initSwiper();
  }
};
</script>

<style scoped lang="less">

/deep/.my-bullet {
  width: 10px;
  height: 10px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  opacity: 1;
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }
}

/deep/.my-bullet-active {
  width: 36px;
  height: 10px;
  background: #FFFFFF;
  border-radius: 5px;
  opacity: 1;
}

.banner {
  width: 100%;
  overflow: hidden;
  position: relative;

  .bannerBg {
    width: 100%;
  }

  .fontImg {
    position: absolute;
    width: 44%;
    top: 20%;
    left: 12.5%;
  }

  .linkImg {
    position: absolute;
    width: 12%;
    top: 56%;
    left: 17.5%;

    img {
      width: 100%;
    }
  }

  .otherImg {
    position: absolute;
    bottom: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    left: 10%;
    width: 80%;
    padding: 0px 0;

    p {
      width: 84%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      img {
        width: 26%;
      }
    }
  }
}

.bannerPhone {
  height: 70px;
  background: #060922;
  width: 100%;
  text-align: center;
  line-height: 70px;
  font-size: 18px;
  color: #fff;

  span {
    color: #0091ff;
  }

  a {
    background: #0091ff;
    border-radius: 4px;
    color: #fff;
    padding: 5px 30px;
    font-size: 16px;
    margin-left: 40px;
  }
}

.section.one {
  position: relative;

  .title::after {
    margin: 20px auto 50px;
  }

  .companyPay {
    width: 66%;

    & > img {
      width: 100%;
    }

    height: auto;
    margin: 0 auto;

    &.two {
      width: 100%;
    }
  }
}

.section.two {
  width: 68%;
  padding: 0 16%;
  background: url(https://image.ceekee.com/pc/tripwise/20210106_10.png) no-repeat center 0;
  height: auto;
  clear: both;

  .title {
    p {
      color: #fff !important;
    }
  }

  .sectionTwo {
    margin-top: 50px;
    position: relative;

    .sectionTwo_li {
      position: absolute;
      text-align: center;
      border-radius: 15px;
      width: 25%;
      z-index: 10;

      & > img {
        width: 100%;
      }

      .sectionTwo_intro {
        position: absolute;
        top: 17%;
        left: 34%;
      }

      &.one {
        position: inherit;
        margin-left: 5%;
      }

      &.two {
        width: 25%;
        left: 26%;
        top: -40px;

        .sectionTwo_intro {
          left: 28%;

          img {
            margin: 48% 0 15%;
          }
        }
      }

      &.three {
        width: 25%;
        left: 47.5%;
        top: -40px;

        .sectionTwo_intro {
          left: 28%;

          img {
            margin: 54% 0 28%;
          }
        }
      }

      &.four {
        left: 67.5%;
        top: 0;
        z-index: 0;

        .sectionTwo_intro {
          left: 28%;

          img {
            margin: 32% 0 13%;
          }
        }
      }
    }
  }
}

.section.three {
  .appointment {
    clear: both;
    margin: 45px auto 60px;
    display: block;
    width: 10%;
  }

  .sectionThree {
    width: 85%;
    max-width: 1522px;
    margin: -30px auto 0;
    height: auto;
    overflow: hidden;

    .sectionThree_li {
      width: 20%;
      float: left;
      position: relative;

      img {
        width: 109%;
      }
    }
  }
}

.section.four {
  background: url(https://image.ceekee.com/pc/tripwise/20210106_25.png) no-repeat center 0;
  height: auto;
  overflow: hidden;
  padding-bottom: 60px;

  .title {
    p {
      color: #fff !important;
    }
  }

  .sectionFour {
    width: 60%;
    margin: -50px auto 0;
    padding-bottom: 20px;
    height: auto;
    overflow: hidden;

    .sectionFour_li {
      width: 32%;
      height: 280px;
      box-shadow: 0px 0px 16px rgba(229, 229, 229, 0.5);
      border-radius: 12px;
      float: left;
      background: #fff;
      text-align: center;
      margin: 100px 2% 0 0;
      color: #222;

      &:nth-child(3n) {
        margin-right: 0;
      }

      img {
        margin-top: -50px;
        width: 80px;
      }

      p {
        font-size: 30px;
        line-height: 62px;
        margin: 30px 0 10px;
      }

      span {
        font-size: 16px;
        line-height: 34px;
      }
    }
  }
}

.title {
  text-align: center;
  padding-top: 60px;
  letter-spacing: 3px;

  &::before {
    content: "";
    display: block;
    margin: 10px auto;
    width: 120px;
    height: 25px;
    border: 2px solid #ffbe2e;
    border-bottom: none;
  }

  &::after {
    content: "";
    display: block;
    margin: 20px auto 70px;
    width: 120px;
    height: 2px;
    background: #ffbe2e;
  }

  p {
    font-size: 44px;
    color: #222;
    margin-bottom: 20px;
    line-height: 60px;
  }

  span {
    font-size: 34px;
    color: #999;
    line-height: 22px;
  }
}

@media screen and (max-width: 1600px) {
  .section.two {
    width: 80%;
    padding: 0 10%;
  }

  .section.four .sectionFour {
    width: 80%;
  }

  .title p {
    font-size: 40px;
    margin-bottom: 15px;
  }

  .title span {
    font-size: 30px;
  }
}

@media screen and (max-width: 1400px) {
  .section.one .companyPay .right p font {
    font-size: 14px;
  }

  .section.one .companyPay .right p i {
    font-size: 56px;
  }

  .title p {
    font-size: 36px;
    margin-bottom: 10px;
  }

  .title span {
    font-size: 26px;
  }
}

@media screen and (max-width: 1300px) {
  .section.one .companyPay .left {
    width: 37%;
  }

  .section.one .companyPay .right {
    width: 60%;
  }

  .section.one .companyPay.sec .onright {
    width: 33%;
  }

  .title p {
    font-size: 32px;
    line-height: 45px;
  }

  .title span {
    font-size: 22px;
  }
}
</style>