var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"banner swiper-slide"},[_c('img',{staticClass:"bannerBg",attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210106_1.png"}}),_c('img',{staticClass:"fontImg",attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210115_11.png"}}),_c('router-link',{staticClass:"linkImg",attrs:{"to":this.$config.appointment_url}},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210106_2.png"}})]),_vm._m(0)],1)]),_c('div',{staticClass:"swiper-pagination"})]),_c('div',{staticClass:"bannerPhone"},[_vm._v(" 现在咨询（180-5110-2396） "),_c('span',[_vm._v("免费赠送 企业差旅管理执行方案一份")]),_c('router-link',{attrs:{"to":this.$config.appointment_url}},[_vm._v("立即获取")])],1),_vm._m(1),_vm._m(2),_c('div',{staticClass:"section three"},[_vm._m(3),_vm._m(4),_c('router-link',{attrs:{"to":this.$config.appointment_url}},[_c('img',{staticClass:"appointment",attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210106_24.png"}})])],1),_vm._m(5),_c('Partner')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"otherImg"},[_c('p',[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20200117_2.png"}}),_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20200117_1.png"}}),_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20200117_3.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section one"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("一站式采购+企业支付+费控为核心")]),_c('span',[_vm._v("帮助企业提升差旅管理、控制费用支出")])]),_c('div',{staticClass:"companyPay"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210116_61.png"}})]),_c('div',{staticClass:"companyPay two"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210116_71.png"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section two"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("直击企业差旅痛点")]),_c('span',[_vm._v("提升各类角色的满意度")])]),_c('div',{staticClass:"sectionTwo"},[_c('div',{staticClass:"sectionTwo_li one"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20200116_1.png"}})]),_c('div',{staticClass:"sectionTwo_li two"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20200116_2.png"}})]),_c('div',{staticClass:"sectionTwo_li three"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20200116_3.png"}})]),_c('div',{staticClass:"sectionTwo_li four"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20200116_4.png"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('p',[_vm._v("全新的企业差旅管理流程")]),_c('span',[_vm._v("告别传统/低效的企业差旅")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sectionThree"},[_c('div',{staticClass:"sectionThree_li"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210115_21.png"}})]),_c('div',{staticClass:"sectionThree_li"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210115_22.png"}})]),_c('div',{staticClass:"sectionThree_li"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210115_23.png"}})]),_c('div',{staticClass:"sectionThree_li"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210115_24.png"}})]),_c('div',{staticClass:"sectionThree_li"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210115_25.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section four"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("6大管家式服务")]),_c('span',[_vm._v("站在企业角度解决所有差旅问题")])]),_c('div',{staticClass:"sectionFour"},[_c('div',{staticClass:"sectionFour_li"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210106_26.png"}}),_c('p',[_vm._v("一站式采购")]),_c('span',[_vm._v(" 差旅出行消费一站解决 "),_c('br'),_vm._v("杜绝跨平台消费的困扰 ")])]),_c('div',{staticClass:"sectionFour_li"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210106_27.png"}}),_c('p',[_vm._v("严选服务")]),_c('span',[_vm._v(" 海量供应商严选资源 "),_c('br'),_vm._v("总能找到您想要的低价 ")])]),_c('div',{staticClass:"sectionFour_li"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210106_28.png"}}),_c('p',[_vm._v("统一结算")]),_c('span',[_vm._v(" 月结垫资服务 "),_c('br'),_vm._v("避免反复请款，不占用 "),_c('br'),_vm._v("企业资金流 ")])]),_c('div',{staticClass:"sectionFour_li"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210106_29.png"}}),_c('p',[_vm._v("数据分析")]),_c('span',[_vm._v(" 部门/项目/节省/浪费等27个 "),_c('br'),_vm._v("纬度的数据分析，专业差旅建议 ")])]),_c('div',{staticClass:"sectionFour_li"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210106_30.png"}}),_c('p',[_vm._v("专属VIP")]),_c('span',[_vm._v(" 7*24小时在线服务 "),_c('br'),_vm._v("2对1微信群处理，高速响应 ")])]),_c('div',{staticClass:"sectionFour_li"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210106_31.png"}}),_c('p',[_vm._v("协议托管")]),_c('span',[_vm._v(" 支持及酒店集团、 "),_c('br'),_vm._v("各大航司的协议托管 ")])])])])])
}]

export { render, staticRenderFns }